<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}康养设备</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="商品名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="品牌" prop="brand">
				<el-input v-model.trim="ruleForm.brand" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="商品编号" prop="serialNumber">
				<el-input v-model.trim="ruleForm.serialNumber" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="产地" prop="producingArea">
				<el-input v-model.trim="ruleForm.producingArea" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="电源方式" prop="powerSupply">
				<el-input v-model.trim="ruleForm.powerSupply" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="理疗方式" prop="physiotherapyMethod">
				<el-input v-model.trim="ruleForm.physiotherapyMethod" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="适用部位" prop="applicablePosition">
				<el-input v-model.trim="ruleForm.applicablePosition" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="适用类型" prop="applicableTypeDictionaryItemId">
				<el-select
					v-model="ruleForm.applicableTypeDictionaryItemId"
					placeholder="请选择"
					:disabled="isShowDetail"
					@change="changeSelect($event, 'applicableTypeDictionaryItemId')"
				>
					<el-option v-for="item in suitTypeList" :key="item.id" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="适宜人群" prop="appropriateCrowd" class="tagsClass">
				<el-tag :key="tag" v-for="tag in ruleForm.appropriateCrowd" :closable="!isShowDetail" :disable-transitions="false" @close="handleClose(tag)">
					{{ tag }}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="inputVisible"
					v-model="inputValue"
					ref="saveTagInput"
					size="small"
					@keyup.enter.native="handleInputConfirm"
					@blur="handleInputConfirm"
				>
				</el-input>
				<el-button
					v-if="!inputVisible && ruleForm.appropriateCrowd.length < 4 && !isShowDetail"
					class="button-new-tag"
					size="small"
					@click="showInput"
					>+ 添加标签</el-button
				>
			</el-form-item>
			<el-form-item label="国产/进口" prop="orginCode">
				<el-select v-model="ruleForm.orginCode" placeholder="请选择" :disabled="isShowDetail">
					<el-option label="国产" value="C"></el-option>
					<el-option label="进口" value="F"></el-option>
				</el-select>
			</el-form-item>
			<ImageAndVideo
				:required="true"
				:imageData="ruleForm.images"
				ref="ImageAndVideo"
				title="上传图文内容"
				:disabled="isShowDetail"
				modulePath="HEALTH_DEVICE"
			></ImageAndVideo>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	components: {
		Upload: () => import('@/components/Upload/index.vue'),
		ImageAndVideo: () => import('@/components/ImageAndVideo/index.vue'),
	},
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				name: '',
				brand: '',
				serialNumber: '',
				producingArea: '',
				powerSupply: '',
				physiotherapyMethod: '',
				applicablePosition: '',
				applicableTypeDictionaryItemId: '',
				appropriateCrowd: [],
				orginCode: '',
				images: [],
			},
			suitTypeList: [],
			rules: {
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				brand: [{ required: true, message: '请输入', trigger: 'blur' }],
				serialNumber: [{ required: true, message: '请输入', trigger: 'blur' }],
				producingArea: [{ required: true, message: '请输入', trigger: 'blur' }],
				powerSupply: [{ required: true, message: '请输入', trigger: 'blur' }],
				physiotherapyMethod: [{ required: true, message: '请输入', trigger: 'blur' }],
				applicablePosition: [{ required: true, message: '请输入', trigger: 'blur' }],
				applicableTypeDictionaryItemId: [{ required: true, message: '请选择', trigger: 'change' }],
				appropriateCrowd: [{ required: true, message: '请选择', trigger: 'blur' }],
				orginCode: [{ required: true, message: '请选择', trigger: 'change' }],
			},
			saveLoading: false,
			link: '',
			attachmentInfo: [],
			inputVisible: false,
			inputValue: '',
		};
	},
	props: ['isShowDetail'],
	watch: {},
	created() {},
	methods: {
		init(data) {
			this.createdVisible = true;
			this.link = data.link;
			this.getSuitTypeList();
			this.getAttachment();
			if (data.row) {
				this.getDetail(data.link);
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			// 新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		// 获取详情
		getDetail(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.setRuleForm(res.data.collection[0]);
					}
				})
				.catch((e) => {});
		},
		// 获取附件组
		getAttachment() {
			this.$http
				.get(this.api['AttachmentGroups#index'].href, { params: { subjectTypeCode: 'HEALTH_DEVICE' } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.attachmentInfo = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取设备类型
		getSuitTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, {
					params: { dictionaryCode: 'DEVICE_APPLICABLE_TYPE', size: 9999, current: 1 },
				})
				.then((res) => {
					if (res.data && res.data.success) {
						this.suitTypeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 修改/查看
		setRuleForm(data) {
			this.ruleForm = JSON.parse(JSON.stringify(data));
			this.ruleForm.applicableTypeDictionaryItemId = data.applicableTypeDictionaryItem?.id || null;
			this.ruleForm.appropriateCrowd = data.appropriateCrowd?.split(',') || [];
			this.ruleForm.images = data.attachmentDtoMap['PHOTO'].map((item) => {
				return {
					basicInfoImg: [
						{
							title: item.title,
							url: item.link,
							id: item.id,
						},
					],
					description: item.description,
				};
			});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs.ImageAndVideo.images = [];
			this.ruleForm.images = [];
			this.$refs[formName].resetFields();
		},
		changeSelect(val, type) {
			this.$set(this.ruleForm, type, val);
			this.$forceUpdate();
		},
		handleClose(tag) {
			this.ruleForm.appropriateCrowd.splice(this.ruleForm.appropriateCrowd.indexOf(tag), 1);
			this.$forceUpdate();
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick((_) => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.ruleForm.appropriateCrowd.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		//保存
		onSave(formName) {
			this.$refs[formName].validate((valid) => {
				let images = this.$refs.ImageAndVideo.images;
				if (images.length == 0) {
					this.$refs.ImageAndVideo.showError = true;
					return;
				}
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						attachments: images.map((item) => {
							return {
								link: item.basicInfoImg?.find((v) => v.title).url,
								subjectTypeCode: this.attachmentInfo.find((iter) => iter.code == 'PHOTO').subjectTypeCode,
								title: item.basicInfoImg?.find((v) => v.title).title,
								attachmentGroupId: this.attachmentInfo.find((iter) => iter.code == 'PHOTO').id,
								mimeType: item.basicInfoImg?.find((v) => v.title).title.split('.')[1],
								description: item.description,
							};
						}),
						appropriateCrowd: this.ruleForm.appropriateCrowd.join(','),
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}

	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
	.tagsClass {
		.el-tag {
			margin-right: 16px;
		}
		.el-input {
			width: 88px;
		}
		.el-button--default {
			margin-left: 0;
			padding: 9px 16px;
		}
	}
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
	width: 100px;
	height: 100px;
}
/deep/ .el-upload {
	.el-button--primary {
		border-color: #c4c4c4;
		color: #333;
		background-color: #fff;
		margin-left: 0;
	}
	.is-disabled {
		background-color: #f4f3f3 !important;
		border-color: #e4e7ed !important;
		color: #c0c4cc !important;
	}
}
/deep/ .el-upload--picture-card {
	width: 100px;
	height: 100px;
	line-height: 100px;
}
/deep/ .el-button {
	margin-left: 16px;
}
.hide {
	// display: none;
	/deep/ .el-upload-list {
		.el-icon-close,
		.el-icon-close-tip {
			display: none;
		}
	}
}

/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
</style>